/* @import url('https://rsms.me/inter/inter.css'); */
 @import url('https://fonts.cdnfonts.com/css/inter');
 :root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}
 *, html, body {
   font-family: 'Inter', monospace, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   margin: 0;
   padding: 0;
   max-width: 100vw;
 }
 
 section {
   max-width: 40vw;
   margin: 0 auto;
   /* background: #a09e9ea3; */
   margin-top: 60px;
   margin-bottom: 60px;
   /* padding: 40px; */
 }

 @media (max-width: 900px) {
  section {
   max-width: 80vw;
   /* padding: 0; */
  }
  /* body {
   background: #e8e8e8;
  } */
 }
 .icon {
   margin-bottom: 40px;
 }
 h1 {
  color: #111;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
 }
 p {
   font-size: 14px;
   line-height: 1.8;
   margin-top: 14px;

 }
 a {
   font-size: 14px;
   color: #111;
   font-weight: 600;
   text-decoration: none;
   border-bottom: 2px solid #000;
   padding-bottom: 1px;
    &:hover {
      color: #777;
      border-bottom: 2px solid #777;

    }
 }
 